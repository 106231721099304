.addStudent {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgb(0 0 0 / 69%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    &_inner {
        width: 480px;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
        position: relative;
        border-radius: 10px;
        padding: 15px 35px;
        max-height: calc(90vh - 150px);
        box-sizing: border-box;
        overflow-y: scroll;
    }

    &_inner::-webkit-scrollbar {
        display: none;
    }


    &_topHead {
        position: relative;
        padding: 18px 0px;

        .head {
            margin: 0;
            font-size: 26px;
            color: #474747;
            font-weight: bold;
        }

        .cardNumber {
            margin: 0;
            font-size: 18px;
            color: #5b5b5b;
        }

        .notMe {
            background-color: #ECE9E9;
            padding: 8px 40px;
            border-radius: 5px;
            border: 1px solid #858585;
            color: #5b5b5b;
            margin-top: 15px;
            outline: none;
            font-size: 18px;
            font-weight: lighter;
            font-family: custom-font4;
            transition: 0.2s ease-in-out;
        }


        .cross {
            position: absolute;
            right: 6px;
            top: 24px;
            color: #E30613;
            font-size: 28px;
            cursor: pointer;
        }

        .backBtn {
            position: absolute;
            left: -5px;
            top: 26px;
            color: #06323F;
            font-size: 22px;
            cursor: pointer;
        }
    }


    &_userDetail {
        padding: 10px 0px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    &_child {
        .name {
            margin: 0;
            color: #06323F;
            font-size: 22px;
        }

        .desc {
            margin: 0;
            font-size: 13px;
            font-family: custom-font4;
        }

        .availableBal {
            margin: 0;
            text-align: right;
            font-family: custom-font6;
            color: #474747;
            font-size: 13px;
        }

        .amount {
            margin: 0;
            text-align: right;
            font-size: 27px;
            font-weight: bold;
        }
    }

    &_inputBox {
        display: flex;
        flex-direction: column;
        margin: 10px 0;

        label {
            margin: 4px 0;
            color: #363535;
            font-size: 14px;
            font-family: custom-font6;
        }

        input {
            padding: 12px 20px;
            border-radius: 8px;
            background-color: #F6F6F6;
            outline: none;
            border: none;
            color: #595959;
        }

        input::placeholder {
            color: #A5A5A5;
            font-size: 13px;
        }

        select {
            padding: 12px 20px;
            border-radius: 8px;
            background-color: #F6F6F6;
            outline: none;
            border: none;
            color: #595959;
        }
    }


    &_confirm {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: 10px 0;
    }


    &_confirmChild {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .amount {
            margin: 0;
            font-size: 33px;
            font-family: custom-font5;
            color: #474747;
        }

        .amountText {
            margin: 0;
            color: #06323F;
            font-size: 18px;
            font-weight: 600;
            font-family: custom-font2;
            position: relative;
            bottom: 3px;
        }

        button {
            padding: 15px 0px;
            background-color: #F7C857;
            outline: none;
            border: none;
            border-radius: 8px;
            font-weight: 600;
            // margin-top: 8px;
            color: #343434;
            transition: 0.2s ease;
            text-transform: capitalize;
            font-family: custom-font6;
            font-size: 15px;
            text-align: center;
        }

        button:hover {
            background-color: #e3be66;
        }
    }

    &_cardAmounts {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin: 10px 0;
    }

    &_cardAmountChild {

        .lable {
            margin: 0;
            color: #A5A5A5;
            margin: 2px 0;
        }

        p {
            margin: 0;
            margin: 2px 0;
            color: #474747;
        }
    }


    @media (max-width : 800px) {
        &_inner {
            width: 95%;
        }
    }


    @media (max-width: 422px) {
        &_topHead {

            .head {
                margin: 8px 0;
                font-size: 16px;
                color: #474747;
                font-weight: bold;
            }
        }


        &_amountBoxChild {
            p {
                font-size: 20px;
            }

            img {
                width: 50px;
            }
        }

        &_child {
            .name {
                font-size: 18px;
            }

            .desc {
                font-size: 10px;
            }

            .availableBal {
                font-size: 11px;
            }

            .amount {
                font-size: 22px;
            }

        }

        .addStudent_confirmChild .amountText {
            font-size: 12px;
        }

        .addStudent_confirmChild .amount {
            font-size: 18px;
        }

        .addStudent_confirmChild button {
            font-size: 11px;
        }

    }

}