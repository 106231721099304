.studentDetails {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgb(0 0 0 / 69%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    &_inner {
        width: 480px;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
        position: relative;
        border-radius: 10px;
        padding: 0px 35px;
        max-height: calc(90vh - 150px);
        box-sizing: border-box;
        overflow-y: scroll;
    }

    &_inner::-webkit-scrollbar {
        display: none;
    }

    &_topHead {
        position: relative;
        padding: 18px 0px;

        .head {
            margin: 0;
            font-size: 28px;
            color: #474747;
            font-weight: bold;
        }

        .email {
            margin: 0;
            font-size: 15px;
            color: #474747;
            font-weight: lighter;
            font-family: custom-font4;
        }

        .notMe {
            background-color: #ECE9E9;
            padding: 8px 40px;
            border-radius: 5px;
            border: 1px solid #858585;
            color: #5b5b5b;
            margin-top: 15px;
            outline: none;
            font-size: 18px;
            font-weight: lighter;
            font-family: custom-font4;
            transition: 0.2s ease-in-out;
            position: relative;
        }

        .homeIcon {
            width: 20px;
            position: absolute;
            left: 10px;
            top: 12.5px;
        }


        .cross {
            position: absolute;
            right: 6px;
            top: 24px;
            color: #E30613;
            font-size: 28px;
            cursor: pointer;
        }

        .backBtn {
            position: absolute;
            left: -5px;
            top: 28px;
            color: #06323F;
            font-size: 22px;
            cursor: pointer;
        }
    }


    &_scroller {
        display: flex;
        flex-direction: column;
        position: relative;
        height: 480px;
        overflow-y: scroll;
        margin: 0px 0;
    }


    &_scroller::-webkit-scrollbar {
        width: 0;
        height: 2px;
        border-radius: 10px;
    }

    &_scroller::-webkit-scrollbar-thumb {
        background: lightgrey;
    }

    @media (max-width: 800px) {
        &_inner {
            width: 95%;
        }
    }


    @media (max-width: 422px) {
        &_topHead {
            position: relative;
            padding: 30px 0px;

            .head {
                font-size: 22px;
            }

            .email {
                font-size: 13px;
            }

            .notMe {
                background-color: #ECE9E9;
                padding: 8px 40px;
                border-radius: 5px;
                border: 1px solid #858585;
                color: #5b5b5b;
                margin-top: 15px;
                outline: none;
                font-size: 18px;
                font-weight: lighter;
            }

            .cross {
                position: absolute;
                right: 6px;
                top: 30px;
                color: #E30613;
                font-size: 28px;
                cursor: pointer;
            }

            .backBtn {
                position: absolute;
                left: -5px;
                top: 28px;
                color: #06323F;
                font-size: 22px;
                cursor: pointer;
            }
        }
    }

}