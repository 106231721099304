.footer {
    width: 100%;
    padding: 20px 60px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #000000;

    &_child {
        display: flex;

        .bvend {
            color: #FBFBFB;
        }

        p {
            color: #CCCCCC;
            margin: 0 10px;
            font-size: 14px;
        }
    }
}

@media (max-width: 600px) {
    .footer {
        padding: 20px 20px;
    }

    .footer_child p {
        font-size: 3vw;
    }
}