* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style-type: none;
}


body::-webkit-scrollbar {
    width: 2px
}

body::-webkit-scrollbar-thumb {
    background: #F6A56F;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

::selection {
    background-color: #EB772F;
    color: white;
}

// toastify css
@import "react-toastify/dist/ReactToastify.css";

// Font Family
@import "../styles/FontFamily/fontFamily";


// Modals
@import "./components/modals/studentDetails";
@import "./components/modals/topUp";
@import "./components/modals/payment";
@import "./components/modals/paymentSuccesfull";
@import "./components/modals/addStudent";


// Components
@import "./components/studentList";
@import "./components/banner";
@import "./components/navbar";
@import "./components/applyNewStudent";
@import "./components/footer";
@import "./components/loader";
@import "./components/miniloader";


// No Data Found
@import "./components/noDataFound";