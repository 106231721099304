.noDataFound {
    width: 100%;
    max-height: calc(90vh - 150px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 20px 0;

    &_msg {
        text-align: center;

        p {
            margin: 10px 0;
            color: rgb(121, 121, 121);
            font-weight: 600;
            font-family: custom-font5;
            font-size: 19px;
        }

        span {
            font-size: 18px;
            font-family: custom-font5;
        }

        img {
            margin: 10px 0;
            width: 100px;
        }
    }



}