.studentList {
    padding: 10px 10px;
    background-color: transparent;
    border: 1px solid #727272;
    margin: 10px 0px;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 8px;


    &_top {
        padding: 0 10px;

        .name {
            margin: 0;
            color: #06323f;
            font-size: 22px;
            font-weight: 580;
            font-family: custom-font2;
        }
    }


    &_info {
        display: grid;
        grid-template-columns: 1.5fr 1fr;
        padding: 0 10px;
    }


    &_child {

        .desc {
            margin: 0;
            font-size: 12.5px;
            color: #474747;
            font-weight: lighter;
            font-family: custom-font6;
        }

        .amount {
            font-size: 24px;
            color: #474747;
            font-weight: bold;
            margin: 0;
            font-family: custom-font5;
        }

        .lable {
            font-size: 12px;
            margin: 0;
            color: #484848;
            position: relative;
            bottom: 3px;
            font-family: custom-font6;
        }

        .topUpBtn {
            width: 100%;
            padding: 10px 0;
            background-color: #F7C857;
            outline: none;
            border: none;
            border-radius: 6px;
            font-weight: lighter;
            margin-top: 8px;
            color: #343434;
            transition: 0.2s ease;
            text-transform: uppercase;
        }

        .topUpBtn:hover {
            background-color: #e3be66;
        }
    }



    @media (max-width : 500px) {
        &_child {

            .desc {
                font-size: 12px;
            }

            .amount {
                font-size: 24px;
            }
        }
    }


    @media (max-width: 422px) {
        &_child {

            .desc {
                font-size: 11px;
            }

            .amount {
                font-size: 16px;
                margin-top: 6px;
            }

            .lable {
                font-size: 14px;
                position: relative;
                bottom: 0px;
            }

            .topUpBtn {
                width: 80%;
                margin-left: 16px;
            }
        }
    }


    @media (max-width: 340px) {
        .studentList_child .lable {
            font-size: 9px;
            position: relative;
            bottom: 0px;
        }
    }

}