.navbar {
    width: 100%;
    padding: 40px 95px;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;


    img {
        width: 180px;
    }
}


@media (max-width: 900px) {
    .navbar {
        display: flex;
        justify-content: center;
    }
}