@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap");

@font-face {
  src: url("./AvenirNext-DemiBold.ttf");
  font-family: custom-font;
}

@font-face {
  src: url("./Belgrano-Regular.ttf");
  font-family: custom-font2;
}

@font-face {
  src: url("./CarterOne-Regular.ttf");
  font-family: custom-font3;
}

@font-face {
  src: url("./Open\ Sans\ Regular.ttf");
  font-family: custom-font4;
}

@font-face {
  src: url("./OpenSans-Bold.ttf");
  font-family: custom-font5;
}


@font-face {
  src: url("./OpenSans-SemiBold.ttf");
  font-family: custom-font6;
}

body {
  font-family: "custom-font", "custom-font2", "custom-font3", "custom-font4", "custom-font5", "custom-font6";
}