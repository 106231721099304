.banner {
    // height: 100%;
    width: 100%;
    padding: 50px 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background: url("../../assets/Bvend-Student-Assets-21.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;


    &_msg {
        margin: 10px 10px;
        color: white;
        font-size: 15px;
    }

    &_leftImg {
        display: flex;
        padding: 10px 0px;
        margin: 35px 0px;
        flex-shrink: 0;
        width: 80%;


        img {
            width: 100%;
        }

        p {
            margin: 0;
            font-size: 28px;
            color: white;
            font-family: custom-font2;
        }

        .toptup {
            font-family: custom-font3;
            color: #eeb17a;
            font-size: 55px;
        }
    }

    &_rightImg {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 80%;

        img {
            width: 100%;
        }
    }


    &_child {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 0 80px;
        margin: 80px 0;
    }


    &_inputWrap {
        width: 70%;
        position: relative;
        height: 60px;

        input {
            width: 100%;
            height: 100%;
            border-radius: 50px;
            padding-left: 20px;
            padding-right: 60px;
            outline: none;
            background-color: transparent;
            border: 1px solid #FFFFFF;
            color: #FFFFFF;
        }

        input::placeholder {
            color: #C6CACF;
            font-size: 14px;
        }

        .nextIcon {
            width: 45px;
            position: absolute;
            right: 8px;
            top: 6px;
            cursor: pointer;
        }
    }



}


@media (max-width: 1150px) {
    .banner_inputWrap .nextIcon {
        width: 33px;
        position: absolute;
        right: 8px;
        top: 13px;
    }

    .banner_inputWrap input::placeholder {
        font-size: 10px;
    }
}


@media (max-width: 900px) {
    .banner {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        height: auto;

        &_child {
            height: auto;
            margin: 30px 0;
        }
    }


    .banner_inputWrap {
        width: 100%;
    }

    .banner_rightImg {
        width: 100%;
    }

    .banner_inputWrap .nextIcon {
        width: 35px;
        position: absolute;
        right: 6px;
        top: 11px;
    }

    .banner_inputWrap input {
        padding-left: 15px;
        padding-right: 60px;
    }

    .banner_inputWrap input::placeholder {
        font-size: 10px;
    }

    .banner_leftImg {
        display: flex;
        width: 100%;
        text-align: center;
        justify-content: center;
        margin-top: 105px;
    }

    .banner_leftImg p {
        font-size: 19px;
    }

    .banner_leftImg .toptup {
        font-size: 41px;
    }

}

@media (max-width : 400px) {
    .banner_inputWrap {
        width: 100%;
        height: 50px;
    }

    .banner_inputWrap input {
        padding-left: 8px;
        padding-right: 40px;
        font-size: 10px;
    }

    .banner_inputWrap .nextIcon {
        width: 28px;
        position: absolute;
        right: 5px;
        top: 10px;
    }


    .banner_inputWrap input::placeholder {
        font-size: 8px;
    }

    .banner_msg {
        font-size: 11px;
    }

}