.paymentSuccesfull {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgb(0 0 0 / 69%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &_msg {
        margin: 10px 0px;
        text-align: left;
        font-size: 13px;
    }


    &_inner {
        width: 350px;
        background-color: #f8c957;
        display: flex;
        flex-direction: column;
        position: relative;
        border-radius: 10px;
        padding: 4px 26px;
        max-height: calc(90vh - 150px);
        box-sizing: border-box;
        overflow-y: scroll;
    }

    &_inner::-webkit-scrollbar {
        display: none;
    }

    &_topHead {
        position: relative;
        padding: 13px 0px;
        text-align: center;

        .head {
            margin: 0;
            font-size: 22px;
            color: #000000;
            font-weight: bold;
        }

        .email {
            margin: 0;
            font-size: 15px;
            color: #474747;
            font-weight: lighter;
            font-family: custom-font4;
        }

        .notMe {
            background-color: #ECE9E9;
            padding: 8px 40px;
            border-radius: 5px;
            border: 1px solid #858585;
            color: #5b5b5b;
            margin-top: 15px;
            outline: none;
            font-size: 18px;
            font-weight: lighter;
            font-family: custom-font4;
            transition: 0.2s ease-in-out;
            position: relative;
        }

        .homeIcon {
            width: 20px;
            position: absolute;
            left: 10px;
            top: 12.5px;
        }


        .cross {
            position: absolute;
            right: 0px;
            top: 17px;
            color: #E30613;
            font-size: 24px;
            cursor: pointer;
        }

        .backBtn {
            position: absolute;
            left: -5px;
            top: 28px;
            color: #06323F;
            font-size: 22px;
            cursor: pointer;
        }
    }

    &_tickInfo {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;

        .tickIcon {
            width: 60px;
            margin: 10px 0;
        }

        .amount {
            margin: 0;
            font-size: 40px;
            color: #000000;
            font-family: custom-font5;
            margin-top: 10px;
        }

        .lable {
            margin: 0;
            color: #06323F;
            font-family: custom-font2;
            font-size: 20px;
            font-weight: 600;
        }

        .msg {
            margin: 0;
            color: #777777;
            font-family: custom-font2;
            font-weight: 550;
            margin-top: 20px;
            font-size: 15px;
            text-align: center;
        }
    }


    &_send {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 15px;

        input {
            padding: 12px 20px;
            border-radius: 8px;
            background-color: #F6F6F6;
            outline: none;
            border: none;
            width: 100%;
        }

        input::placeholder {
            color: #A5A5A5;
            font-size: 15px;
        }

        button {
            width: 180px;
            padding: 13px 0px;
            background-color: #474747;
            color: #f9f9f9;
            border: none;
            outline: none;
            border-radius: 10px;
            margin: 20px 0;
            transition: 0.2s ease;
        }

        button:hover {
            background-color: #333333;
        }
    }



    @media (max-width : 900px) {
        &_inner {
            width: 90%;
        }

        .paymentSuccesfull_send input {
            width: 63%;
        }
    }


    @media (max-width : 600px) {
        .paymentSuccesfull_send input {
            width: 100%;
        }
    }


    @media (max-width: 400px) {
        .paymentSuccesfull_topHead .head {
            margin: 4px 0;
            font-size: 17px;
        }
    }
}