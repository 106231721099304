.applyNewStudent {
    width: 100%;
    padding: 60px 0;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;

    div {
        text-align: center;
    }

    .head {
        font-family: custom-font3;
        font-size: 40px;
        color: #eeb17a;
    }

    .desc {
        color: #06323f;
        font-size: 30px;
        font-weight: bold;
    }

    button {
        padding: 18px 60px;
        background-color: #F7C857;
        outline: none;
        border: none;
        border-radius: 6px;
        font-weight: lighter;
        margin-top: 8px;
        color: #343434;
        transition: 0.2s ease;
        text-transform: capitalize;
        font-size: 20px;
    }

    button:hover {
        background-color: #e3be66;
    }


    @media (max-width: 900px) {
        .head {
            font-size: 26px;
        }

        .desc {
            font-size: 25px;
        }

        button {
            padding: 12px 34px;
            font-weight: lighter;
            color: #343434;
            font-size: 15px;
        }
    }
}